import React, { createContext, ReactNode, useContext } from "react";
import { useAlert } from "./AlertProvider";
import { apiParams } from "./typs";

// Define the types for your context

// Create a context with default values
const UserFunctionsContext = createContext<TUserFunctions | undefined>(undefined);

// Custom hook to use the context
export const useUserFunctions = () => {
    const context = useContext(UserFunctionsContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};

interface UserFuncProviderProps {
    children: ReactNode;
    user: TUser;
    setUser;
}
export type TUserFunctions = {
    // getUserData: (params: TGetUserDataParamas) => void;
    user: TUser;
    addPacking: (props: TAddPackingProps) => void;
    getPacking: (props: TGetPackingProps) => void;
    addSubscriptions: (props: TAddSubscriptions) => void;
    getSubscriptions: (props: TGetSubscriptionsProps) => void;
    getSettings: (props: apiParams<{ pcname: string }>) => void;
};

export const UserFuncProvider: React.FC<UserFuncProviderProps> = ({ children, user, setUser }) => {
    const alert = useAlert();
    const value: TUserFunctions = {
        user,
        addPacking: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "settings/add_packing/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, true);
        },
        getPacking: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "settings/get_packing/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        addSubscriptions: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "settings/add_subscriptions/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, true);
        },
        getSubscriptions: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "settings/get_subscriptions/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        getSettings: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "settings/get_settings/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    setUser((prev) => ({ ...prev, settings: res.data.data }));
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, true);
        },
    };

    return <UserFunctionsContext.Provider value={value}>{children}</UserFunctionsContext.Provider>;
};

export type TGetUserDataParamas = {
    type: string;
    num: number;
    condition: "FIRST" | "LAST" | "SPE";
    onSuccess?: (res) => void;
    onError?: (res) => void;
};

type TGetPackingProps = apiParams<object>;

type TAddPackingProps = apiParams<{
    name;
}>;

type TGetSubscriptionsProps = apiParams<object>;
type TAddSubscriptions = apiParams<{
    name;
    price;
}>;

export type TUser = {
    name: string;
    dataname: string;
    token: string;
    rank: number;
    rank_name: string;
    pc_info: {
        data: {
            serial_number: string;
            company_id: number;
            description: string;
            system_name: string;
            size: string;
            model: string;
            users: number;
        };
        lic: string[];
        sync_date: string;
    };
    settings: {
        proforma: TInvoiceSettings;
        purchase: TInvoiceSettings;
        sales: TInvoiceSettings;
        system: {
            default: {
                language: number;
                use_dark_mode: number;
                show_last_cost_in_search: number;
                show_av_cost_in_search: number;
                hiddin_invoice_types: string;
            };
        };
        eod: {
            printers: {
                printers_config: {
                    id: number;
                    config_id: number;
                    name: string;
                    is_active: number;
                    for_client: number;
                    filter: string;
                    silent: number;
                    print_background: number;
                    margins: string;
                    landscape: number;
                    scale_factor: number;
                    pages_per_sheet: number;
                    copies: number;
                    duplex_mode: string;
                    dpi: string;
                    header: string;
                    footer: string;
                    page_size: string;
                }[];
            };
        };
        lists: {
            inv_types: {
                id: number;
                type: string;
                name: string;
                branch: number;
            }[];
            vch_types: {
                id: number;
                type: string;
                name: string;
            }[];
            branches: {
                num: number;
                name: string;
            }[];
            departments: {
                id: number;
                name: string;
                description: string;
            }[];
            packing_ids: {
                id: number;
                name: string;
            }[];
            currency: {
                order: number;
                code: string;
                name: string;
                name2: string;
                symbol: string;
                rate: number;
                default_sale_price: number;
                primary: number;
            }[];
            sman: {
                id: number;
                name: string;
                description: string;
            }[];
            jobs: {
                id: number;
                name: string;
                description: string;
            }[];
            att_trans_types: {
                id: number;
                description:
                    | "Loan"
                    | "Note"
                    | "Bonus"
                    | "Loan Payment"
                    | "Note Paymnet"
                    | "Bonus Received";
                type: "C" | "D";
            }[];
            sets: {
                id: number;
                name: string;
                desc: string;
            }[];
            categories: {
                id: number;
                name: string;
                desc: string;
            }[];
            units: {
                id: number;
                name: string;
                desc: string;
            }[];
            brands: {
                id: number;
                name: string;
                desc: string;
            }[];
            colors: {
                id: number;
                name: string;
                desc: string;
            }[];
            families: {
                id: number;
                name: string;
                desc: string;
            }[];

            groups: {
                id: number;
                name: string;
                desc: string;
            }[];

            shelfs: {
                id: number;
                name: string;
                desc: string;
            }[];
            models: {
                id: number;
                name: string;
                desc: string;
            }[];
            goods_types: {
                id: number;
                name: string;
                desc: string;
            }[];
            subscription_programs: {
                id: number;
                name: string;
                price: number;
            }[];
            other_databases: {
                id: number;
                host: string;
                port: number;
                username: string;
                dbname: string;
                active: number;
            }[];
            privilege: {
                accounts: {
                    auto_payment: number;
                    create_new_account: number;
                    generate_auto_close: number;
                    get_accounts: number;
                    max_id: number;
                    merge_accounts: number;
                    statement_of_account: number;
                    trial_balance: number;
                    update_account: number;
                };
                admins: {
                    create_new_admin: number;
                    edit_user: number;
                    edit_user_privilege: number;
                    get_admin_data: number;
                    get_admins_name: number;
                    get_admins_ranks: number;
                    get_user_privilege: number;
                    login: number;
                    select_user_privilege: number;
                    update_user_privilege: number;
                };
                core: {
                    commit_actions: number;
                    edit_actions: number;
                    get_actions: number;
                    get_system_printers: number;
                };
                excel_import: {
                    import_excel: number;
                    open_excel: number;
                };
                goods: {
                    add_scale_config: number;
                    create_item_attribute: number;
                    create_new_item: number;
                    delete_item: number;
                    edit_config: number;
                    edit_item: number;
                    edit_item_attribute: number;
                    edit_scale_config: number;
                    get_config: number;
                    get_item_attributes_tables: number;
                    get_items: number;
                    sale_percentage: number;
                    scale_config: number;
                    statement_of_item: number;
                    statistics: number;
                    stock_overview: number;
                    transfer_to_otherbarcode: number;
                };
                invoice: {
                    add_consignment_item: number;
                    add_item: number;
                    allow_change_discount: number;
                    allow_change_price: number;
                    create_inv: number;
                    custom_select: number;
                    delete_consignment_record: number;
                    delete_inv: number;
                    delete_inv_items: number;
                    duplicate_invoice: number;
                    edit_config: number;
                    edit_consignment_return: number;
                    edit_inv: number;
                    edit_inv_items: number;
                    flip_currency: number;
                    get_branches: number;
                    get_config: number;
                    get_currency: number;
                    get_end_of_day_id: number;
                    get_inv_data: number;
                    get_inv_types: number;
                    get_invs: number;
                    get_jobs: number;
                    get_sman: number;
                    get_whqty: number;
                    post_to_accounting: number;
                    select_consignment_account_record: number;
                    select_consignment_item: number;
                    select_consignments: number;
                    transfer_to_otherbarcode: number;
                };
                pos: {
                    add_item: number;
                    add_menu_config: number;
                    add_menu_item: number;
                    calculate_end_of_day: number;
                    close_end_of_day: number;
                    edit_config: number;
                    edit_end_of_day_void: number;
                    edit_menu_config: number;
                    get_config: number;
                    get_holded_invoice: number;
                    get_menu_config: number;
                    get_menu_items: number;
                    refund_invoice: number;
                    remove_menu_item: number;
                };
                settings: {
                    add_branch: number;
                    add_default_setting: number;
                    add_inv_types: number;
                    add_job: number;
                    add_printer: number;
                    add_printer_config: number;
                    add_sman: number;
                    add_vch_types: number;
                    edit_branch: number;
                    edit_currencies: number;
                    edit_default_settings: number;
                    edit_general_settings: number;
                    edit_inv_types: number;
                    edit_job: number;
                    edit_printer: number;
                    edit_sman: number;
                    edit_vch_types: number;
                    get_default_settings: number;
                    get_printer_config: number;
                    get_printers: number;
                    get_system_settings: number;
                };
                vouchers: {
                    add_trans: number;
                    auto_fill_trans: number;
                    auto_fill_trnas: number;
                    confirm_trans: number;
                    create_vch: number;
                    delete_vch: number;
                    edit_inv: number;
                    edit_main_account: number;
                    edit_vch_trans: number;
                    get_receipt_data: number;
                    get_temp_trans: number;
                    get_vch_data: number;
                    get_vch_types: number;
                    page_startup: number;
                    remove_vch_trans: number;
                    reset_trans: number;
                };
                xreports: {
                    create_report: number;
                    get_reports: number;
                    save_report: number;
                };
            };
        };
        company_settings: {
            id: string;
            comp_name: string;
            comp_name_2: string;
            tax_number: string;
            tax_percentage: string;
            enforce_tax: string;
            country: string;
            province: string;
            city: string;
            street: string;
            building: string;
            floor: string;
            year_from: string;
            year_to: string;
            use_system_rate_pos: string;
            email1: string;
            email2: string;
            phone1: string;
            phone2: string;
            phone3: string;
            website1: string;
            website2: string;
            pos_switch_cur: string;
            pos_round_on: string;
            pos_round_con: string;
        };
    };
    privileges: {
        accounts: {
            auto_payment: TNBoolean;
            create_new_account: TNBoolean;
            generate_auto_close: TNBoolean;
            get_accounts: TNBoolean;
            max_id: TNBoolean;
            merge_accounts: TNBoolean;
            statement_of_account: TNBoolean;
            trial_balance: TNBoolean;
            update_account: TNBoolean;
            allow_unlock_voucher: TNBoolean;
        };
        admins: {
            create_new_admin: TNBoolean;
            edit_user: TNBoolean;
            edit_user_privilege: TNBoolean;
            get_admin_data: TNBoolean;
            get_admins_name: TNBoolean;
            get_admins_ranks: TNBoolean;
            get_user_privilege: TNBoolean;
            login: TNBoolean;
            select_user_privilege: TNBoolean;
            update_user_privilege: TNBoolean;
        };
        core: {
            commit_actions: TNBoolean;
            edit_actions: TNBoolean;
            get_actions: TNBoolean;
            get_system_printers: TNBoolean;
        };
        excel_import: {
            import_excel: TNBoolean;
            open_excel: TNBoolean;
        };
        goods: {
            add_scale_config: TNBoolean;
            create_item_attribute: TNBoolean;
            create_new_item: TNBoolean;
            delete_item: TNBoolean;
            edit_config: TNBoolean;
            edit_item: TNBoolean;
            edit_item_attribute: TNBoolean;
            edit_scale_config: TNBoolean;
            get_config: TNBoolean;
            get_item_attributes_tables: TNBoolean;
            get_items: TNBoolean;
            sale_percentage: TNBoolean;
            scale_config: TNBoolean;
            statement_of_item: TNBoolean;
            statistics: TNBoolean;
            stock_overview: TNBoolean;
            transfer_to_otherbarcode: TNBoolean;
        };
        invoice: {
            add_consignment_item: TNBoolean;
            add_item: TNBoolean;
            allow_change_discount: TNBoolean;
            allow_change_price: TNBoolean;
            create_inv: TNBoolean;
            custom_select: TNBoolean;
            delete_consignment_record: TNBoolean;
            delete_inv: TNBoolean;
            delete_inv_items: TNBoolean;
            duplicate_invoice: TNBoolean;
            edit_config: TNBoolean;
            edit_consignment_return: TNBoolean;
            edit_inv: TNBoolean;
            edit_inv_items: TNBoolean;
            flip_currency: TNBoolean;
            get_branches: TNBoolean;
            get_config: TNBoolean;
            get_currency: TNBoolean;
            get_end_of_day_id: TNBoolean;
            get_inv_data: TNBoolean;
            get_inv_types: TNBoolean;
            get_invs: TNBoolean;
            get_jobs: TNBoolean;
            get_sman: TNBoolean;
            get_whqty: TNBoolean;
            post_to_accounting: TNBoolean;
            select_consignment_account_record: TNBoolean;
            select_consignment_item: TNBoolean;
            select_consignments: TNBoolean;
            allow_unlock_invoice: TNBoolean;
            transfer_to_otherbarcode: TNBoolean;
        };
        pos: {
            add_item: TNBoolean;
            add_menu_config: TNBoolean;
            add_menu_item: TNBoolean;
            calculate_end_of_day: TNBoolean;
            close_end_of_day: TNBoolean;
            edit_config: TNBoolean;
            edit_end_of_day_void: TNBoolean;
            edit_menu_config: TNBoolean;
            get_config: TNBoolean;
            get_holded_invoice: TNBoolean;
            get_menu_config: TNBoolean;
            get_menu_items: TNBoolean;
            refund_invoice: TNBoolean;
            remove_menu_item: TNBoolean;
        };
        settings: {
            add_branch: TNBoolean;
            add_default_setting: TNBoolean;
            add_inv_types: TNBoolean;
            add_job: TNBoolean;
            add_printer: TNBoolean;
            add_printer_config: TNBoolean;
            add_sman: TNBoolean;
            add_vch_types: TNBoolean;
            edit_branch: TNBoolean;
            edit_currencies: TNBoolean;
            edit_default_settings: TNBoolean;
            edit_general_settings: TNBoolean;
            edit_inv_types: TNBoolean;
            edit_job: TNBoolean;
            edit_printer: TNBoolean;
            edit_sman: TNBoolean;
            edit_vch_types: TNBoolean;
            get_default_settings: TNBoolean;
            get_printer_config: TNBoolean;
            get_printers: TNBoolean;
            get_system_settings: TNBoolean;
        };
        vouchers: {
            add_trans: TNBoolean;
            auto_fill_trans: TNBoolean;
            auto_fill_trnas: TNBoolean;
            confirm_trans: TNBoolean;
            create_vch: TNBoolean;
            delete_vch: TNBoolean;
            edit_inv: TNBoolean;
            edit_main_account: TNBoolean;
            edit_vch_trans: TNBoolean;
            get_receipt_data: TNBoolean;
            get_temp_trans: TNBoolean;
            get_vch_data: TNBoolean;
            get_vch_types: TNBoolean;
            page_startup: TNBoolean;
            remove_vch_trans: TNBoolean;
            reset_trans: TNBoolean;
        };
        xreports: {
            create_report: TNBoolean;
            get_reports: TNBoolean;
            save_report: TNBoolean;
        };
    };
};
export type TNBoolean = 0 | 1;
export type TInvoiceSettings = {
    default: {
        auto_branch: number;
        default_cr_acc: number;
        default_db_acc: number;
        default_inv_type: string;
        default_sale_price: number;
        allow_sale_under_qty: number;
        show_av_cost;
        show_last_cost;
        show_last_order;
        
        post_on_create;
        post_on_move;
        post_on_print;
        post_on_close;
    };
    table_config: {
        show_under_cost_warning;
        color_width: number;
        disc_width: number;
        exp_width: number;
        header_height: number;
        itemcode_width: number;
        itemname_width: number;
        net_price_width: number;
        notes_width: number;
        originalnumber_width: number;
        price_width: number;
        qty_width: number;
        row_height: number;
        service_width: number;
        show_color: number;
        show_img1: number;
        show_disc: number;
        show_exp: number;
        show_itemname: number;
        show_net_price: number;
        show_notes: number;
        show_originalnumber: number;
        show_pic: number;
        show_price: number;
        show_qty: number;
        show_service: number;
        show_tax: number;
        show_total_disc: number;
        show_total_exp: number;
        show_total_net: number;
        show_total_price: number;
        show_total_tax: number;
        show_type: number;
        tax_width: number;
        total_disc_width: number;
        total_exp_width: number;
        total_net_width: number;
        total_price_width: number;
        total_tax_width: number;
        type_width: number;
        show_web_url: number;
        show_sale1: number;
        sale1_width: number;
        sale2_width: number;
        show_sale2: number;
        sale3_width: number;
        show_sale3: number;
        sale4_width: number;
        show_sale4: number;
        show_sale1_ttc: number;
        sale1_ttc_width: number;
        sale2_ttc_width: number;
        show_sale2_ttc: number;
        sale3_ttc_width: number;
        show_sale3_ttc: number;
        sale4_ttc_width: number;
        show_sale4_ttc: number;
        show_packing_unit;
        packing_unit_width;
        show_packing_name;
        packing_name_width;
        show_packing_qty;
        packing_qty_width;
        show_group;
        group_width;
        show_posted_qty;
        posted_qty_width;
    };
    printers: {
        printers_config: {
            id: number;
            config_id: number;
            name: string;
            is_active: number;
            for_client: number;
            filter: string;
            silent: number;
            print_background: number;
            margins: string;
            landscape: number;
            scale_factor: number;
            pages_per_sheet: number;
            copies: number;
            duplex_mode: string;
            dpi: string;
            header: string;
            footer: string;
            page_size: string;
        }[];
    };
};
