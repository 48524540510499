import React, { createContext, ReactNode, useContext } from "react";
import { useAlert } from "./AlertProvider";
import { TTabsContainerApiRef } from "../components/App/NavTabs/TabsContainer";
import { apiParams } from "./typs";

// Define the types for your context
type TInvoiceFunctions = {
    getInvoiceData: (params: TGetInvoiceDataParamas) => void;
    editInvoice: (params: TEditInvoiceParamas) => void;
    createInvoice: (params: TCreateInvoiceParamas) => void;
    addInvoiceItem: (params: TAddInvoiceItemsParamas) => void;
    editInvoiceItem: (params: TEditInvoiceItemsParamas) => void;
    multiEditInvoiceItem: (params: TMultiEditInvoiceItemParamas) => void;
    multiEdit: (params: TMultiEditParamas) => void;
    duplicateInvoice: (params: TDuplicateInvoiceParams) => void;
    postToAccounting: (params: TPostToAccountingParams) => void;
    deleteItem: (params: TDeleteItemParams) => void;
    getNotePresets: (params: TGetNotePresetsParams) => void;
    importInvItems: (params: TImportInvItemsParams) => void;
    getSerialNumbers: (params: apiParams<{ invId; line; itemcode; all; join }>) => void;
    getSerialNumberTrans: (params: apiParams<{ itemcode }>) => void;
    selectSerialNumbers: (params: apiParams<{ invId; line; serials }>) => void;
    editSerialNumber: (params: apiParams<{ serial_id; inv_id; line; field; value }>) => void;
    deleteSerialNumbers: (params: apiParams<{ inv_id; line }>) => void;
    getCustomPostings: (params: apiParams<object>) => void;
    createCustomPosting: (params: apiParams<{ name: string }>) => void;
    getCustomPostingChildren: (params: apiParams<{ parentId: string | number }>) => void;
    createCustomPostingChild: (
        params: apiParams<{ parentId: string | number; account; name; value; type }>
    ) => void;
    deleteCustomPostingChild: (params: apiParams<{ childId; parentId }>) => void;
    getInvVch: (params: apiParams<{ invId }>) => void;
    addInvVch: (params: apiParams<{ invId; accountId; dbcr }>) => void;
    editInvVch: (params: apiParams<{ invId; line; field; value }>) => void;
    removeInvVch: (params: apiParams<{ invId; line }>) => void;
    autoFillVch: (params: apiParams<{ invId; line }>) => void;
    confirmInvVch: (params: apiParams<{ invId }>) => void;
};

// Create a context with default values
const InvoiceFunctionsContext = createContext<TInvoiceFunctions | undefined>(undefined);

// Custom hook to use the context
export const useInvoiceFunctions = () => {
    const context = useContext(InvoiceFunctionsContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};

interface InvoiceFuncProviderProps {
    children: ReactNode;
    tabContainerApiRef: React.MutableRefObject<TTabsContainerApiRef>;
}

export const InvoiceFuncProvider: React.FC<InvoiceFuncProviderProps> = ({
    children,
    tabContainerApiRef,
}) => {
    const alert = useAlert();
    const value: TInvoiceFunctions = {
        getInvoiceData: ({
            type,
            num = 1,
            condition,
            onSuccess = (res) => {},
            onError = (res) => {},
        }) => {
            const url = "invoice/get_inv_data/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            const fdata = { type: type, num: num, condition: condition };
            alert.postdata(url, cbnf, fdata, false);
        },
        editInvoice: ({ inv_id, field, value, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/edit_inv/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            const fdata = {
                inv_id: inv_id,
                field: field,
                value: value,
            };
            alert.postdata(url, cbnf, fdata, false);
        },
        createInvoice: ({
            type,
            branch,
            defaultConfig,
            onSuccess = (res) => {},
            onError = (res) => {},
        }) => {
            const url = "invoice/create_inv/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            const fdata = { type: type, defaultConfig: defaultConfig, branch: branch };
            alert.postdata(url, cbnf, fdata, true);
        },
        addInvoiceItem: ({
            inv_id,
            line,
            itemcode,
            page,
            defSalePrice,
            rate,
            account_id,
            qty,
            allow_sale_under_qty,
            onSuccess = (res) => {},
            onError = (res) => {},
        }) => {
            const url = "invoice/add_item/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            const fdata = {
                inv_id: inv_id,
                line: line,
                itemcode: itemcode,
                page: page,
                defSalePrice: defSalePrice,
                rate: rate,
                account_id: account_id,
                qty: qty,
                allow_sale_under_qty: allow_sale_under_qty,
            };
            alert.postdata(url, cbnf, fdata, true);
        },
        editInvoiceItem: ({ onSuccess = (res) => {}, onError = (res) => {}, ...data }) => {
            const url = "invoice/edit_inv_items/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        duplicateInvoice: ({
            inv_id,
            change_type,
            same_rate,
            same_date,
            selected_items,
            conf,
            onSuccess = (res) => {},
            onError = (res) => {},
        }) => {
            const url = "invoice/duplicate_invoice/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            const fdata = {
                inv_id: inv_id,
                change_type: change_type,
                same_rate: same_rate,
                same_date: same_date,
                selected_items: selected_items,
                conf: conf,
            };
            alert.postdata(url, cbnf, fdata, true);
        },
        postToAccounting: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/post_to_accounting/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, true);
        },
        deleteItem: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/delete_inv_items/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, true);
        },
        multiEditInvoiceItem: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/multi_edit_inv_item/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, true);
        },
        getNotePresets: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/get_note_presets/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        multiEdit: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/multi_edit/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        importInvItems: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/import_inv_items/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, true);
        },
        getSerialNumbers: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/get_serial_numbers/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        selectSerialNumbers: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/select_serial_numbers/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        editSerialNumber: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/edit_serial_number/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        deleteSerialNumbers: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/delete_serial_numbers/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        getSerialNumberTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/get_serial_number_trans/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        getCustomPostings: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/get_custom_postings/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        createCustomPosting: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/create_custom_posting/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        getCustomPostingChildren: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/get_custom_posting_children/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        createCustomPostingChild: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/create_custom_posting_child/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        deleteCustomPostingChild: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/delete_custom_posting_child/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        getInvVch: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/get_inv_vch/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        addInvVch: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/add_inv_vch/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, true);
        },
        editInvVch: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/edit_inv_vch/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        removeInvVch: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/remove_inv_vch/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, true);
        },
        autoFillVch: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/auto_fill_vch/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, true);
        },
        confirmInvVch: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "invoice/confirm_inv_vch/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, true);
        },
    };

    return <InvoiceFunctionsContext.Provider value={value}>{children}</InvoiceFunctionsContext.Provider>;
};

export type TGetNotePresetsParams = apiParams<{
    //
}>;
export type TMultiEditInvoiceItemParamas = apiParams<{
    inv_id: any;
    line;
    data;
}>;
export type TPostToAccountingParams = apiParams<{
    inv_id: any;
}>;
export type TDeleteItemParams = apiParams<{
    inv_id;
    items: any[];
}>;
export type TMultiEditParamas = apiParams<{
    inv_id;
    field: string;
    value: string | number;
}>;

export type TGetInvoiceDataParamas = {
    type: string;
    num: number;
    condition: "FIRST" | "LAST" | "SPE";
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TEditInvoiceParamas = {
    inv_id: any;
    field: any;
    value: any;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TCreateInvoiceParamas = {
    type: any;
    branch;
    defaultConfig;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TEditInvoiceItemsParamas = {
    inv_id;
    line;
    field;
    value;
    allow_sale_under_qty;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TAddInvoiceItemsParamas = {
    inv_id;
    line;
    itemcode;
    page;
    defSalePrice;
    rate;
    account_id;
    qty;
    allow_sale_under_qty;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TDuplicateInvoiceParams = {
    inv_id;
    change_type;
    same_rate;
    same_date;
    selected_items;
    conf;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};

export type TImportInvItemsParams = apiParams<{
    inv_id: any;
    data;
    createItem: boolean;
    updateItem: boolean;
}>;
